import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


export default function Navbar(props) {
  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
    <div className="container-fluid">
      <a className="navbar-brand" href="/">{props.title}</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/">{props.NavLink1}</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">{props.NavLink2}</Link>
          </li>
          {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href="/">Action</a></li>
              <li><a className="dropdown-item" href="/">Another action</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href="/">Something else here</a></li>
            </ul>
          </li> */}
        </ul>
        <div className="form-check form-switch">
            <input className="form-check-input" onChange={props.toggleDarkMode} type="checkbox" role="switch" id="switchVMode" />
            <label className={`form-check-label text-${props.mode==='light'?'dark':'light'}`} htmlFor="switchVMode">Toggle Dark Mode</label>
        </div>
      </div>
    </div>
  </nav>
  );
}

Navbar.propTypes = {
    title: PropTypes.string.isRequired,
    NavLink1: PropTypes.string.isRequired,
    NavLink2: PropTypes.string.isRequired
}

Navbar.defaultProps = {
    title: "Error Text",
    NavLink1: "Error Text",
    NavLink2: "Error Text"
}