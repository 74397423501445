import './App.css';
import React, {useState} from 'react';
import Navbar from './components/Navbar';
import About from './components/About';
import Textform from './components/Textform';
import Alert from './components/Alert';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Footer from './components/Footer';

function App() {
  const [mode, setMode] = useState('light');
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg:  message,
      type: type
    });
    setTimeout(() => {
      setAlert(null)
    }, 1500);
  }

  const toggleDarkMode = () => {
    if(mode==='light'){
      setMode('dark');
      document.body.style.backgroundColor = "#282c34";
      showAlert("Dark mode enabled", "success");
    }
    else{
      setMode('light')
      document.body.style.backgroundColor = "#fdfdfd";
      showAlert("Dark mode disabled", "success");
    }
  }

  return (
    <>
    <Router>
      <Navbar title="TextUtility" NavLink1="Home" NavLink2="About" mode={mode} toggleDarkMode={toggleDarkMode}/>
      <Alert alert={alert} />
      
      <Switch>
          <Route exact path="/about">
            <About mode={mode}/>
          </Route>
          <Route exact path="/">
          <Textform title="Fastest online tool to manipulate your text for FREE!" mode={mode} toggleDarkMode={toggleDarkMode} showAlert={showAlert}/>
          </Route>
        </Switch>
        </Router>
        <Footer mode={mode} />
    </>
  );
}

export default App;
