import React, {useState} from 'react';
import PropTypes from 'prop-types'; 

export default function Textform(props) {
  
  //conversion To UPPERCASE
  const changeToUpper = () => {
    let newText = text.toUpperCase();
    setText(newText);
    newText!==''?props.showAlert('Your text has been changed to UPPERCASE', 'success'):props.showAlert('Please enter some text', 'warning');
  }
  //conversion To lowercase
  const changeToLower = () => {
    let newText = text.toLowerCase();
    setText(newText);
    newText!==''?props.showAlert('Your text has been changed to lowercase', 'success'):props.showAlert('Please enter some text', 'warning');
  }  

  //conversion To Title case
  const changeToTitleClick=()=>{

    let newText = text.split(' ').map(t => t.charAt(0).toUpperCase() + t.slice(1).toLowerCase()).join(" ");
    setText(newText);
    newText!==''?props.showAlert('Your text has been changed to Title case', 'success'):props.showAlert('Please enter some text', 'warning');

  }

  //conversion To Sentence Case
  const changeToSentencecase=()=>{

    let newText = text.split('. ').map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(". ");
    setText(newText);
    newText!==''?props.showAlert('Your text has been changed to Sentence Case', 'success'):props.showAlert('Please enter some text', 'warning');

  }
  //Copy Text
  const copyTextClipboard=()=>{
    navigator.clipboard.writeText(text);
    text!==''?props.showAlert('Your text has been copied to Clipboard', 'success'):props.showAlert('Please enter some text', 'warning');
    // if(displayState.display === 'none'){
    //   changeDstate({
    //     display: 'block',
    //   })
    // }
    // else{
    //   changeDstate({
    //     display: 'none',
    //   })
    // }
    // setSucess("Text Copied Successfully");
  }
  
  //Remove Extra Space
  const removeExtraSpace = () => {
    let newText =  text.split(/[  ]+/);
    setText(newText.join(" "));
    newText!==''?props.showAlert('Extra spaces are removed from your text', 'success'):props.showAlert('Please enter some text', 'warning');
  }

  //Clear Text Area
  const clearText = () => {
    let newText = "";
    setText(newText);
    props.showAlert('Textarea is cleared.', 'success');
  } 

  const changeInputText = (event) => {
    setText(event.target.value);
  }

  // Declare a new state variable, which we'll call ".........."
  const [text, setText] = useState("");

  // const [successDtext, setSucess] = useState("");

  // const [displayState, changeDstate] = useState({
  //     display: "none",
  // });

  return (
      <>
      <div className={`container text-${props.mode==='dark'?'light':'dark'}`}>
        <div className="my-3">
          <h2><label htmlFor="textInp" className="form-label">{props.title}</label></h2>
          <textarea className={`form-control bg-${props.mode} text-${props.mode==='light'?'dark':'light'}`} placeholder='Type or paste your content here' id="textInp" rows="6" value={text} onChange={changeInputText}></textarea>
        </div>
        <div className="actions">
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'primary'} rounded-0 me-2 my-1`} onClick={changeToUpper}>UPPERCASE</button>
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'primary'} rounded-0 me-2 my-1`} onClick={changeToLower}>lowercase</button>
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'primary'} rounded-0 me-2 my-1`} onClick={changeToTitleClick}>Title Case</button>
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'primary'} rounded-0 me-2 my-1`} onClick={changeToSentencecase}>Sentence case</button>
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'info'} rounded-0 me-2 my-1`} onClick={removeExtraSpace}>Remove Extra Space</button>
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'dark'} rounded-0 me-2 my-1`} onClick={clearText}>Clear Text</button>
        </div>
      </div>
      <div className={`container text-${props.mode==='dark'?'light':'dark'}`}>
        <div className="my-3">
          <h3>Text Summary:</h3>
          <p className="m-0">Words Count: {text.length>0 ? text.trim().split(/\s+/).length : 0} Words and {text.length} Characters</p>
          <p className="m-0">Read Time: {0.008 * (text.length>0 ? text.trim().split(" ").length : 0)} mins</p>
          <hr />
          <h3 className="mt-3">Text Preview:</h3>
          <p>{text.length===0?"Nothing to preview":text}</p>      
          <button disabled={text.length===0} className={`btn btn-${text.length===0?'secondary':'dark'} rounded-0 me-2 my-1`} onClick={copyTextClipboard}>Copy Text to Clipboard</button>
        </div>
      </div>
      </>
    );
}
Textform.propTypes = {
  title: PropTypes.string.isRequired
}